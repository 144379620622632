import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { VerticalHomeMenuComponent } from "@presentation/utils/vertical-home-menu/vertical-home-menu.component";
import { NoAuthGuard } from "../../core/auth/guards/no-auth.guard";
import { VerticalMenuComponent } from "../utils/vertical-menu/vertical-menu.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/register/register-option",
  },
  {
    path: "landing",
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: VerticalMenuComponent,
    children: [
      {
        path: "register",
        pathMatch: "full",
        redirectTo: "/register/register-option",
      },
      {
        path: "register",
        loadChildren: () =>
          import("./modules/auth/sign-up/register.module").then(
            (m) => m.RegisterModule
          ),
      },
      {
        path: "password-reset",
        loadChildren: () =>
          import("./modules/auth/passwords/password.module").then(
            (m) => m.PasswordResetModule
          ),
      },
    ],
  },
  {
    path: "login",
    component: VerticalMenuComponent,
    children: [
      {
        path: "login",
        pathMatch: "full",
        redirectTo: "/login/company",
      },
      {
        path: "",
        loadChildren: () =>
          import("./modules/auth/sign-in/sign-in.module").then(
            (m) => m.SignInModule
          ),
      },
    ],
  },
  {
    path: "admin",
    component: VerticalHomeMenuComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./modules/admin/admin.module").then((m) => m.HomeModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/register/register-option",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
