<div class="container-menu py-0">
  <nav class="navbar">
    <div class="flex-column mx-4 logo-home-cont">
      <img class="logo-home" src="\assets\logo-treebu.png" alt="Treebu logo" />
    </div>
    <div class="top-bar-actions row">
      <div class="col-1"><img class="image" [src]="notificationImage" alt="notification"></div>
      <div class="col-2"><img class="image profile" [src]="profileImage" alt="notification"></div>
      <div class="col-6 user-data-container">
        <span class="name">{{userData.name}}</span>
        <br>
        <span class="email">{{userData.email}}</span>
      </div>
      <div class="col d-flex align-items-center">
        <img class="down-img" [src]="downIcon" alt="notification">
      </div>
    </div>
  </nav>
</div>
<div class="col-md-12">
  <div class="main">
    <router-outlet></router-outlet>
  </div>
</div>