import { User } from '../../domain/models/user';
import { CreateUserDto } from '../../domain/dto/create-user-dto';
import { UpdateUserPasswordBody } from '@domain/models/update-user-password-body';
import { ValidateVerificationOtpEmailBody } from '@domain/models/validate-verification-otp-email-body';
import { GetUserParams } from '@domain/models/getUser-params';
import { AddMetamapAttemptBody } from '@domain/models/add-metamap-attempt-body';

export abstract class UserDataSourceService {
  abstract registerUserData(userData: CreateUserDto): Promise<boolean>;
  abstract getUserData(params: GetUserParams): Promise<User>;
  abstract telephoneAlreadyExists(userTelephone: string): Promise<boolean>;
  abstract sendVerificationOtpEmail(email: string): Promise<void>;
  abstract validateVerificationOtpEmail(body: ValidateVerificationOtpEmailBody): Promise<boolean>;
  abstract updateUserPassword(body: UpdateUserPasswordBody): Promise<void>;
  abstract addMetamapAttempt(body: AddMetamapAttemptBody): Promise<boolean>;
}
