import { Injectable } from '@angular/core';
import { Either } from '../../core/helpers/either';
import { SignInData } from '@domain/models/sign-in-data';
import { ErrorFailure, Failure } from '../../core/helpers/failure';
import { AuthRepository } from '@domain/repository/auth-repository';
import { AuthDataSourceService } from '../data-sources/auth-data-source.service';
import { User } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthRepositoryImplService extends AuthRepository {
  constructor(private authDataSourceService: AuthDataSourceService) {
    super()
  }

  override async signInWithEmailAndPassword(signInData: SignInData): Promise<Either<Failure, User>> {
    try {
      return Either.right(await this.authDataSourceService.signInWithEmailAndPassword(signInData));
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.message }));
    }
  }
  override async signOut(userUid: string): Promise<Either<Failure, void>> {
    throw new Error('Method not implemented.');
  }

  override async verifyEmailExistance(email: string): Promise<Either<Failure, boolean>> {
    try {
      return Either.right(await this.authDataSourceService.verifyEmailExistence(email));
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.message }));
    }
  }
}
