import { Injectable } from "@angular/core";
import { AdminDataSourceService } from "../data-sources/admin-data-source.service";
import { RequestService } from "@core/http/request.service";
import { PaginatedParams } from "@domain/models/paginated-params";
import { Service } from "@domain/models/service";
import { TREEBU_CAPITAL } from "@core/constants/environment.constant";
import { firstValueFrom, lastValueFrom } from "rxjs";
import { BeneficiarieModel } from "@domain/models/beneficiarie.model";
import { Agreements } from "@domain/models/agreements";
import { DatePipe } from "@angular/common";
import { LocalStorageService } from "@core/services/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AdminDataSourceImplService extends AdminDataSourceService {
  constructor(
    private client: RequestService,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  private datePipe: DatePipe = new DatePipe("en-US");

  override async getServices(
    params: PaginatedParams
  ): Promise<{ services: Service[]; totalCount: number }> {
    try {
      let response = await lastValueFrom(
        this.client.get<any>(
          `${TREEBU_CAPITAL}/api/treebu-b2b/v1/Service/list?page=${params.page}&pageSize=${params.pageSize}`,
          false,
          localStorage.getItem("accessToken") ?? ""
        )
      );
      const servicesList: Service[] = response.data.Items.map((item: any) => {
        return {
          id: item.Id,
          name: item.Name,
          description: item.Name,
          status: item.Status == "ENABLED" ? true : false,
        };
      });
      const totalCount = response.data.TotalCount;
      return { services: servicesList, totalCount: totalCount };
    } catch (error) {
      throw error;
    }
  }

  override async getBeneficiaries(
    params: PaginatedParams
  ): Promise<{ beneficiaries: BeneficiarieModel[]; totalCount: number }> {
    try {
      let response = await lastValueFrom(
        this.client.get<any>(
          `${TREEBU_CAPITAL}/api/treebu-b2b/v1/Beneficiary/list?page=${params.page}&pageSize=${params.pageSize}`,
          false,
          localStorage.getItem("accessToken") ?? ""
          )
      );
      const beneficiariesList: BeneficiarieModel[] = response.data.Items.map(
        (item: any) => {
          return {
            Id: item.Id,
            CompanyName: item.CompanyName,
            DocumentType: item.DocumentType,
            Document: item.Document,
            PhoneNumber: item.PhoneNumber,
            Email: item.Email,
            AccountId: item.AccountId,
            UuidAccount: item.UuidAccount,
            BankAccounts: item.BankAccounts[0],
          };
        }
      );
      const totalCount = response.data.TotalCount;
      return { beneficiaries: beneficiariesList, totalCount: totalCount };
    } catch (error) {
      throw error;
    }
  }

  override async getAgreements(
    params: PaginatedParams
  ): Promise<{ agreements: Agreements[]; totalCount: number }> {
    try {
      let response = await lastValueFrom(
        this.client.get<any>(
          `${TREEBU_CAPITAL}/api/treebu-b2b/v1/Agreement/list?page=${params.page}&pageSize=${params.pageSize}`,
          false,
          localStorage.getItem("accessToken") ?? ""
          )
      );

      const agreementsList: Agreements[] = response.data.Items.map(
        (item: any) => {
          return {
            id: item.Id,
            name: item.Name,
            description: item.Description,
            updateDate: this.formatDate(new Date(item.UpdatedAt)),
            status: item.Status == "ENABLED" ? true : false,
          };
        }
      );
      const totalCount = response.data.TotalCount;
      return { agreements: agreementsList, totalCount: totalCount };
    } catch (error) {
      throw error;
    }
  }

  override async addServices(data: Service): Promise<Service> {
    try {
      let response = await firstValueFrom<any>(
        this.client.post(
          `${TREEBU_CAPITAL}/api/treebu-b2b/v1/Service`,
          data,
          false,
          localStorage.getItem("accessToken") ?? ""
        )
      );

      return response.data;
    } catch (error: any) {
      throw error;
    }
  }

  private formatDate(date: Date): string {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${day} ${months[monthIndex]} ${year} ${formattedHour}:${minute
      .toString()
      .padStart(2, "0")} ${ampm}`;
  }
}
