import { Injectable } from "@angular/core";
import { User } from "../../domain/models/user";
import { firstValueFrom, lastValueFrom } from "rxjs";
import { Exception } from "../../core/helpers/Exception";
import { CreateUserDto } from "../../domain/dto/create-user-dto";
import { RequestService } from "../../core/http/request.service";
import { TREEBU_CAPITAL } from "../../core/constants/environment.constant";
import { UserTelephoneExistsDTO } from "@domain/dto/user-telephone-exists.dto";
import { UserDataSourceService } from "../data-sources/user-data-source.service";
import { UpdateUserPasswordBody } from "@domain/models/update-user-password-body";
import { ValidateVerificationOtpEmailBody } from "@domain/models/validate-verification-otp-email-body";
import { GetUserParams } from "@domain/models/getUser-params";
import { AddMetamapAttemptBody } from "@domain/models/add-metamap-attempt-body";

@Injectable({
  providedIn: "root",
})
export class UserDataSourceImplService extends UserDataSourceService {
  constructor(private client: RequestService) {
    super();
  }

  override async registerUserData(userData: CreateUserDto): Promise<boolean> {
    try {
      let response = this.client.post(
        `${TREEBU_CAPITAL}/api/treebu-user-unauthenticated/v1/user`,
        userData
      );
      await firstValueFrom(response);
      return true;
    } catch (error: any) {
      throw error;
    }
  }

  override async getUserData(params: GetUserParams): Promise<User> {
    try {
      let response = await lastValueFrom(
        this.client.get<any>(
          `${TREEBU_CAPITAL}/api/treebu-user/v1/User/firebase/${params.uid}`,
          false,
          params.token
        )
      );
      return User.fromJson(response.data);
    } catch (error) {
      throw new Exception("Server error");
    }
  }

  override async telephoneAlreadyExists(
    userTelephone: string
  ): Promise<boolean> {
    try {
      const response = this.client.get<UserTelephoneExistsDTO>(
        `${TREEBU_CAPITAL}/api/treebu-ledger-unauthenticated/v1/User/phone-number?phone=${encodeURIComponent(
          userTelephone
        )}`
      );

      // Convert the Observable to a Promise and await the result
      const result = await firstValueFrom(response);
      // Return true if the phone number exists (data is true)
      return result.data;
    } catch (error) {
      console.error("Server error", error);
      throw new Error("Server error");
    }
  }

  override async sendVerificationOtpEmail(email: string): Promise<void> {
    try {
      let response = await lastValueFrom(
        this.client.post<any>(
          `${TREEBU_CAPITAL}/api/treebu-ledger-unauthenticated/v1/AwsSES/send-activation-otp`,
          { email: email }
        )
      );
      if (response.code != 200) {
        throw new Exception("Server error");
      }
    } catch (error) {
      throw new Exception("Server error");
    }
  }

  override async validateVerificationOtpEmail(
    body: ValidateVerificationOtpEmailBody
  ): Promise<boolean> {
    try {
      let response = await lastValueFrom(
        this.client.get<any>(
          `${TREEBU_CAPITAL}/api/treebu-ledger-unauthenticated/v1/otp/validate?email=${body.email}&code=${body.otp}`
        )
      );
      return response.data;
    } catch (error) {
      throw new Exception("Server error");
    }
  }

  override async updateUserPassword(
    body: UpdateUserPasswordBody
  ): Promise<void> {
    try {
      await lastValueFrom(
        this.client.post<any>(
          `${TREEBU_CAPITAL}/api/treebu-ledger-unauthenticated/v1/user/reset-password`,
          {
            email: body.email,
            newPassword: body.newPassword,
          }
        )
      );
    } catch (error) {
      throw new Exception("Server error");
    }
  }

  override async addMetamapAttempt(
    body: AddMetamapAttemptBody
  ): Promise<boolean> {
    try {
      let response = await lastValueFrom(
        this.client.post<any>(
          `${TREEBU_CAPITAL}/api/treebu-ledger-unauthenticated/v1/User/metamap-attempt`,
          body
        )
      );
      return true;
    } catch (error) {
      throw new Exception("Server error");
    }
  }
}
