import { Component } from "@angular/core";
import { TimerService } from "@core/helpers/timer-services.service";
import { FirebaseAuthService } from "@core/services/firebase-auth.service";
import { environment } from "@environments/environment";
import { AuthDataSourceImplService } from "src/app/infraestructure/data-sources-impl/auth-data-source-impl.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.css",
})
export class AppComponent {
  title = "Treebu.B2B";
  constructor(
    private _timerService: TimerService,
    private _authService: AuthDataSourceImplService
  ) {}

  ngOnInit(): void {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && accessToken !== "null") {
      this._timerService.startTimer(environment.timerRefreshToken, () => {
        this._authService.refreshFirebaseToken();
      });
    }
  }
}
