<div class="container-menu py-0">
  <nav class="navbar">
    <img
      *ngIf="user != ''"
      class="burger"
      src="\assets\icons\menu-hamburguesa.png"
      alt="Menu hamburguesa"
      (click)="sidebarVisible = true"
    />
    <div class="flex-column logo-home-cont">
      <img class="logo-home" src="\assets\logo-treebu.png" alt="Treebu logo" />
    </div>

    <!-- <img
      [src]="user?.photo == '' || user?.photo == null ? '/assets/user-default.png' : user?.photo"
      width="24"
      class="vertical-align-middle img-profile-ini"
      (click)="closeSidebarVisible = true"
      alt="usuario"
    /> -->
    <img
      *ngIf="user != ''"
      src="\assets\icons\user.svg"
      width="24"
      class="vertical-align-middle img-profile-ini"
      (click)="closeSidebarVisible = true"
      alt="usuario"
    />
    <ul class="navbar-nav ml-auto nav-user desk">
      <!-- <li class="nav-item">
        <img src="\assets\icons\notification.svg" alt="notificación" />
      </li> -->
      <li class="nav-item">
        <p-menu #menu [model]="items" [popup]="true"></p-menu>
        <!-- in case of login -->
        <div
          *ngIf="user != ''"
          class="drop-ini"
          type="button"
          (click)="menu.toggle($event)"
          icon="pi pi-bars"
        >
          <!-- <img
            [src]="user.photo == '' || user.photo == null ? '/assets/user-default.png' : user.photo"
            width="24"
            class="vertical-align-middle img-profile-ini"
            alt="nombre usario"
          /> -->
          <img
            src="\assets\icons\user.svg"
            width="24"
            class="vertical-align-middle img-profile-ini logout"
            alt="nombre usario"
          />
          <!-- <label class="label-drop">{{ user.displayName }}</label> -->
          <div class="container-username">
            <label class="label-drop">User name</label>
            <label class="label-down">emailprueba.com</label>
          </div>
          <div class="container-icon-drop">
            <i class="pi pi-angle-down icon-drop-menu"></i>
          </div>
        </div>
        <!-- NO LOGIN -->
        <div
          class="drop-no-log"
          type="button"
          (click)="menu.toggle($event)"
          icon="pi pi-bars"
        >
          <!-- <label class="label-drop">{{ user.displayName }}</label> -->
          <div class="">
            <label class="label-no-login">Ayuda</label>
          </div>
          <div class="container-icon-drop">
            <i class="pi pi-angle-down icon-drop-menu"></i>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</div>
<div *ngIf="user != ''" class="container-fluid px-3">
  <div class="row">
    <div class="col-md-2 bar pt-4">
      <!-- SIDEBAR -->
      <div class="list-group desk">
        <!-- DASHBOARD -->
        <!-- <app-vertical-menu-item
          [title]="'Dashboard'"
          [routerTo]="'#'"
          [imageActive]="'/assets/icons/home.svg'"
          [imageInactive]="'/assets/icons/home.svg'"
          [altImage]="'Home'"
        ></app-vertical-menu-item> -->
      </div>
    </div>
    <div class="col-md-10 p-5">
      <div class="main">
        <!-- <app-breadcrums class="desk"></app-breadcrums> -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<div *ngIf="user ==''" class="col-md-12">
  <div class="main">
    <!-- <app-breadcrums class="desk"></app-breadcrums> -->
    <router-outlet></router-outlet>
  </div>
</div>
<!-- SIDEBAR TOP -->
<p-sidebar
  [(visible)]="sidebarVisible"
  position="top"
  [style]="{ height: '70vh' }"
>
  <div class="list-group">
    <!-- DASHBOARD -->
    <app-vertical-menu-item
      [title]="'Dashboard'"
      [routerTo]="'#'"
      [imageActive]="'/assets/icons/home.svg'"
      [imageInactive]="'/assets/icons/home.svg'"
      [altImage]="'Home'"
    ></app-vertical-menu-item>
  </div>
</p-sidebar>

<!-- SIDEBAR TOP -->
<p-sidebar
  [(visible)]="closeSidebarVisible"
  position="bottom"
  [style]="{ height: '20vh' }"
>
  <div class="container" (click)="logout()" role="button">
    <div class="row">
      <span>Cerrar sesión</span>
    </div>
  </div>
</p-sidebar>
