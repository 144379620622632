import { Injectable } from "@angular/core";
import { AdminRepository } from "@domain/repository/admin-repository";
import { AdminDataSourceService } from "../data-sources/admin-data-source.service";
import { Either } from "@core/helpers/either";
import { ErrorFailure, Failure } from "@core/helpers/failure";
import { PaginatedParams } from "@domain/models/paginated-params";
import { Service } from "@domain/models/service";
import { BeneficiarieModel } from "@domain/models/beneficiarie.model";
import { Agreements } from "@domain/models/agreements";
import { LocalStorageService } from "@core/services/local-storage.service";

@Injectable({
  providedIn: "root",
})

export class AdminRepositoryImplService extends AdminRepository {
  constructor(private adminDataSourceService: AdminDataSourceService) {
    super();
  }

  override async getServices(
    params: PaginatedParams,
  ): Promise<Either<Failure, { services: Service[]; totalCount: number }>> {
    try {
      return Either.right(
        await this.adminDataSourceService.getServices(params)
      );
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }

  override async getBeneficiaries(
    params: PaginatedParams
  ): Promise<
    Either<Failure, { beneficiaries: BeneficiarieModel[]; totalCount: number }>
  > {
    try {
      return Either.right(
        await this.adminDataSourceService.getBeneficiaries(params)
      );
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }

  override async getAgreements(
    params: PaginatedParams
  ): Promise<
    Either<Failure, { agreements: Agreements[]; totalCount: number }>
  > {
    try {
      return Either.right(
        await this.adminDataSourceService.getAgreements(params)
      );
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }

  override async addServices(data: Service): Promise<Either<Failure, Service>> {
    try {
      let response = await this.adminDataSourceService.addServices(data);
      return Either.right(response);
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }
}
