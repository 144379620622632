import { Component } from "@angular/core";
import { ToastsService } from "../toasts.service";

@Component({
  selector: "app-info-toast",
  templateUrl: "./info-toast.component.html",
  styleUrl: "./info-toast.component.scss",
})
export class InfoToastComponent {
  constructor(public toastsService: ToastsService) {
  }
}
