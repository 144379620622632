export class User {
    id: number;
    metamapAttempts: number;
    metamapOnboarding: boolean | null;
    metamapValidationInProgress: boolean;
    uid: string;
    name: string;
    email: string;
    firebaseToken: string;
    userType: string;
    companyValidationStatus: number;

    constructor({ id, metamapAttempts, metamapOnboarding, metamapValidationInProgress, uid, name, email, firebaseToken, userType, companyValidationStatus }: User) {
        this.id = id;
        this.metamapAttempts = metamapAttempts;
        this.metamapOnboarding = metamapOnboarding;
        this.metamapValidationInProgress = metamapValidationInProgress;
        this.uid = uid;
        this.name = name;
        this.email = email;
        this.firebaseToken = firebaseToken;
        this.userType = userType;
        this.companyValidationStatus = companyValidationStatus;
    }

    static fromJson(json: any): User {
        return new User({
            uid: json.FirebaseUid,
            id: json.Id,
            name: json.FullName,
            email: json.Email,
            firebaseToken: "",
            metamapAttempts: json.MetamapAttempts,
            metamapOnboarding: json.Onboarding,
            metamapValidationInProgress: json.InProcessMetamap,
            userType: json.UserType,
            companyValidationStatus: json.CompanyValidationStatus
        })
    }
}