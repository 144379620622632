import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
// import { AuthService } from '../../core/services/auth.service';
// import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
})
export class VerticalMenuComponent implements OnInit {
  items!: any[];
  user: any;
  itemsMenu!: any[];
  menuOpen: boolean = false;
  sidebarVisible: boolean = false;
  closeSidebarVisible: boolean = false;

  ngOnInit() {
    this.items = [
      {
        label: 'Cerrar sesión',
        icon: 'pi pi-sign-out',
        command: () => {
          this.logout();
        },
      },
    ];
    this.user = "";
    this.termsAndpilicyList();
  }
  currentRoute: string;
  constructor(
    private router: Router, 
    // public authService: AuthService
    ) {
    this.currentRoute = 'Demo';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        if (
          !this.currentRoute.includes('/terms-conditions') &&
          !this.currentRoute.includes('/privacy-policy')
        ) {
          this.termsAndpilicyList();
        }
      }
    });
  }

  logout() {
    // this.authService.logout();
  }

  get isTermsPolicy() {
    return (
      this.currentRoute.includes('/terms-conditions') ||
      this.currentRoute.includes('/privacy-policy')
    );
  }

  termsAndpilicyList() {
    this.itemsMenu = [
      {
        label: 'Términos y politicas',
        icon: 'pi pi-fw pi-file icon-terms',
        routerLink: '/terms-conditions',
        expanded: false,
        items: [
          {
            label: 'Términos y condiciones',
            routerLink: '/terms-conditions',
            command: () => (this.sidebarVisible = false),
          },
          {
            label: 'Políticas de privacidad',
            routerLink: '/privacy-policy',
            command: () => (this.sidebarVisible = false),
          },
        ],
      },
    ];
  }

  onShow() {
    alert();
  }
}
