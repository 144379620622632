import { Component, Input } from "@angular/core";
import { ToastsService } from "../toasts.service";

@Component({
  selector: "app-loading-toast",
  templateUrl: "./loading-dialog.component.html",
  styleUrl: "./loading-dialog.component.scss",
})
export class LoadingDialogComponent {
  @Input() showLoadingToast: boolean = false;

  constructor(public toastsService: ToastsService) {
  }
}
