import { Agreements } from "@domain/models/agreements";
import { BeneficiarieModel } from "@domain/models/beneficiarie.model";
import { PaginatedParams } from "@domain/models/paginated-params";
import { Service } from "@domain/models/service";

export abstract class AdminDataSourceService {
  abstract getServices(params: PaginatedParams): Promise<{ services: Service[], totalCount: number }>;
  abstract addServices(params: Service): Promise<Service>;
  abstract getBeneficiaries(params: PaginatedParams): Promise<{ beneficiaries: BeneficiarieModel[], totalCount: number }>;
  abstract getAgreements(params: PaginatedParams): Promise<{ agreements: Agreements[], totalCount: number }>;
}
