import { FirebaseError } from "firebase/app";
import { Inject, Injectable } from "@angular/core";
import { Exception } from "@core/helpers/Exception";
import { SignInData } from "@domain/models/sign-in-data";
import { fetchSignInMethodsForEmail, User } from "firebase/auth";
import { AuthDataSourceService } from "../data-sources/auth-data-source.service";
import {
  Auth,
  UserCredential,
  signInWithEmailAndPassword,
} from "@angular/fire/auth";

@Injectable({
  providedIn: "root",
})
export class AuthDataSourceImplService extends AuthDataSourceService {
  constructor(@Inject(Auth) private auth: Auth) {
    super();
  }
  userCredential: UserCredential;
  override async signInWithEmailAndPassword(
    signInData: SignInData
  ): Promise<User> {
    try {
      let user: UserCredential | void = await signInWithEmailAndPassword(
        this.auth,
        signInData.email,
        signInData.password
      );
      this.userCredential = user;
      this.saveUserData(user.user);
      console.log(this.userCredential, "user credentials");
      return user.user;
    } catch (error: any) {
      if (
        error instanceof FirebaseError &&
        error.message.includes("wrong-password")
      ) {
        throw new Exception("wrong-password");
      }
      throw new Exception(error.toString());
    }
  }

  override async verifyEmailExistence(email: string): Promise<boolean> {
    try {
      let methods: string[] = await fetchSignInMethodsForEmail(
        this.auth,
        email
      );
      return methods.includes("password");
    } catch (error: any) {
      throw new Exception("Server error");
    }
  }

  saveUserData(user: any) {
    localStorage.setItem("accessToken", user.accessToken);
    localStorage.setItem("user", JSON.stringify(user));
  }

  // Method to refresh Firebase token
  refreshFirebaseToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const user = this.auth.currentUser;
      if (user) {
        user
          .getIdToken(/* forceRefresh */ true)
          .then((token: string) => {
            localStorage.setItem("accessToken", token);
            resolve(token);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject("No authenticated user");
      }
    });
  }
}
