import { Component } from '@angular/core';
import { AppResourcesService } from '../../../core/resources/app-resources.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { User } from '@domain/models/user';

@Component({
  selector: 'app-vertical-home-menu',
  templateUrl: './vertical-home-menu.component.html',
  styleUrls: ['./vertical-home-menu.component.scss'],
})
export class VerticalHomeMenuComponent {
  notificationImage: string = ""
  profileImage: string = ""
  downIcon: string = ""
  userData: User;

  constructor(
    private localStorageService: LocalStorageService
  ) {
    this.notificationImage = AppResourcesService.images.notification
    this.profileImage = AppResourcesService.images.profile
    this.downIcon = AppResourcesService.icons.down
    this.userData = localStorageService.getData("userData")
  }
}
