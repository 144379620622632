// import { getAuth, provideAuth } from '@angular/fire/auth';
// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { BrowserModule } from "@angular/platform-browser";
import {
  BrowserAnimationsModule,
  provideAnimations,
} from "@angular/platform-browser/animations";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { FirebaseAuthService } from "@core/services/firebase-auth.service";
import { AuthRepository } from "@domain/repository/auth-repository";
import { UserRepository } from "@domain/repository/user-repository";
import { GetUserDataUsecase } from "@domain/usecases/get-user-data-usecase";
import { RegisterUserDataUsecase } from "@domain/usecases/register-user-data-usecase";
import { SendVerificationOtpEmailUsecase } from "@domain/usecases/send-verification-otp-email-usecase";
import { SignInWithEmailAndPasswordUsecase } from "@domain/usecases/sign-in-with-email-and-password-usecase";
import { UpdateUserPasswordUsecase } from "@domain/usecases/update-user-password-usecase";
import { ValidateVerificationOtpEmailUsecase } from "@domain/usecases/validate-verification-otp-email-usecase";
import { VerifyEmailExistanceUsecase } from "@domain/usecases/verify-email-existance-usecase";
import { InfoToastComponent } from "@presentation/utils/molecules/toasts/info-toast/info-toast.component";
import { LoadingDialogComponent } from "@presentation/utils/molecules/toasts/loading-dialog/loading-dialog.component";
import { VerticalHomeMenuComponent } from "@presentation/utils/vertical-home-menu/vertical-home-menu.component";
import { DialogModule } from "primeng/dialog";
import { MenuModule } from "primeng/menu";
import { PanelMenuModule } from "primeng/panelmenu";
import { SidebarModule } from "primeng/sidebar";
import { AuthDataSourceImplService } from "src/app/infraestructure/data-sources-impl/auth-data-source-impl.service";
import { UserDataSourceImplService } from "src/app/infraestructure/data-sources-impl/user-data-source-impl.service";
import { AuthDataSourceService } from "src/app/infraestructure/data-sources/auth-data-source.service";
import { UserDataSourceService } from "src/app/infraestructure/data-sources/user-data-source.service";
import { AuthRepositoryImplService } from "src/app/infraestructure/driven-adapters/auth-repository-impl.service";
import { UserRepositoryImplService } from "src/app/infraestructure/driven-adapters/user-repository-impl.service";
import { FIREBASE_CONFIG } from "../../core/constants/environment.constant";
import { VerticalMenuItemComponent } from "../utils/vertical-menu-item/vertical-menu-item.component";
import { VerticalMenuComponent } from "../utils/vertical-menu/vertical-menu.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PasswordResetModule } from "./modules/auth/passwords/password.module";
import { SignInModule } from "./modules/auth/sign-in/sign-in.module";
import { LandingModule } from "./modules/landing/landing.module";
import { LocalStorageService } from "@core/services/local-storage.service";
import { AdminDataSourceService } from "src/app/infraestructure/data-sources/admin-data-source.service";
import { AdminDataSourceImplService } from "src/app/infraestructure/data-sources-impl/admin-data-source-impl.service";
import { AdminRepository } from "@domain/repository/admin-repository";
import { AdminRepositoryImplService } from "src/app/infraestructure/driven-adapters/admin-repository-imp.service";
import { GetServicesUsecase } from "@domain/usecases/get-services-usecase";
import { GetBeneficiariesUsecase } from "@domain/usecases/get-beneficiaries-usecase";
import { GetAgreementsUsecase } from "@domain/usecases/get-agreements-usecase";
import { addServicesUsecase } from "@domain/usecases/add-service-usecase";

@NgModule({
  declarations: [
    AppComponent,
    VerticalMenuItemComponent,
    VerticalMenuComponent,
    VerticalHomeMenuComponent,
    InfoToastComponent,
    LoadingDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DialogModule,
    SidebarModule,
    PanelMenuModule,
    MenuModule,
    BrowserAnimationsModule,
    // Project Modules
    LandingModule,
    PasswordResetModule,
    SignInModule,

    // FIREBASE_CONFIG
    provideFirebaseApp(() => initializeApp(FIREBASE_CONFIG)),
    provideAuth(() => getAuth()),
  ],
  providers: [
    provideAnimationsAsync(),
    // Data sources
    { provide: UserDataSourceService, useClass: UserDataSourceImplService },
    { provide: AuthDataSourceService, useClass: AuthDataSourceImplService },
    { provide: AdminDataSourceService, useClass: AdminDataSourceImplService },
    // Repositories
    { provide: UserRepository, useClass: UserRepositoryImplService },
    { provide: AuthRepository, useClass: AuthRepositoryImplService },
    { provide: AdminRepository, useClass: AdminRepositoryImplService },
    // Use cases
    { provide: GetUserDataUsecase },
    { provide: RegisterUserDataUsecase },
    { provide: SignInWithEmailAndPasswordUsecase },
    { provide: VerifyEmailExistanceUsecase },
    { provide: SendVerificationOtpEmailUsecase },
    { provide: ValidateVerificationOtpEmailUsecase },
    { provide: UpdateUserPasswordUsecase },
    { provide: GetServicesUsecase },
    { provide: GetBeneficiariesUsecase },
    { provide: GetAgreementsUsecase },
    { provide: addServicesUsecase },
    // Service
    { provide: FirebaseAuthService },
    { provide: LocalStorageService },
    provideAnimationsAsync(),
    provideAnimations(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
