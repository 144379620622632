import { Injectable } from '@angular/core';
import { User } from '../../domain/models/user';
import { Either } from '../../core/helpers/either';
import { ErrorFailure, Failure } from '../../core/helpers/failure';
import { UserDataSourceService } from '../data-sources/user-data-source.service';
import { UserRepository } from '../../domain/repository/user-repository';
import { CreateUserDto } from '../../domain/dto/create-user-dto';
import { ValidateVerificationOtpEmailBody } from '@domain/models/validate-verification-otp-email-body';
import { UpdateUserPasswordBody } from '@domain/models/update-user-password-body';
import { GetUserParams } from '@domain/models/getUser-params';
import { AddMetamapAttemptBody } from '@domain/models/add-metamap-attempt-body';

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryImplService extends UserRepository {
  constructor(private userApiService: UserDataSourceService) {
    super()
  }
  override async getUserData(params: GetUserParams): Promise<Either<Failure, User>> {
    try {
      return Either.right(await this.userApiService.getUserData(params));
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }

  override async registerUserData(userData: CreateUserDto): Promise<Either<Failure, boolean>> {
    try {
      let response = await this.userApiService.registerUserData(userData);
      return Either.right(response);
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }

  override async telephoneAlreadyExists(userTelephone: string): Promise<Either<Failure, boolean>> {
    try {
      return Either.right(await this.userApiService.telephoneAlreadyExists(userTelephone));
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }

  override async sendVerificationOtpEmail(email: string): Promise<Either<Failure, void>> {
    try {
      return Either.right(await this.userApiService.sendVerificationOtpEmail(email));
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }
  override async validateVerificationOtpEmail(body: ValidateVerificationOtpEmailBody): Promise<Either<Failure, boolean>> {
    try {
      return Either.right(await this.userApiService.validateVerificationOtpEmail(body));
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }

  override async updateUserPassword(body: UpdateUserPasswordBody): Promise<Either<Failure, void>> {
    try {
      return Either.right(await this.userApiService.updateUserPassword(body));
    } catch (error: any) {
      return Either.left(new ErrorFailure({ message: error.toString() }));
    }
  }

  override async addMetamapAttempt(body: AddMetamapAttemptBody): Promise<Either<Failure, boolean>> {
    try {
      return Either.right(await this.userApiService.addMetamapAttempt(body));
    } catch (error: any) {
      return Either.left(new ErrorFailure({message: error.toString()}));
    }
  }
}
