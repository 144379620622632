import { Component, Input } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-vertical-menu-item',
  templateUrl: './vertical-menu-item.component.html',
  styleUrls: ['./vertical-menu-item.component.scss'],
})
export class VerticalMenuItemComponent {
  @Input() routerTo: string = ''; // Propiedad para la currentRoute
  @Input() title: string = '';
  @Input() imageActive: string = '';
  @Input() imageInactive: string = '';
  @Input() altImage: string = '';
  currentRoute: string;
  currentImage: string = '';
  constructor(private router: Router) {
    this.currentRoute = 'DEMO';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.getImage();
      }
    });
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.getImage();
  }

  getImage() {
    this.currentImage = this.currentRoute.includes(this.routerTo)
      ? this.imageActive
      : this.imageInactive;
  }
}
